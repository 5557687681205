<template>
  <div id="content">
    <!-- <Stages /> -->

    <div v-if="stage.id !== 'configurator' && !result.current" class="stage">

      <h1 class="stage__title">Порівняння вартості терапії:<br>Тева та аналоги</h1>

      <div class="number">

        <button
          :class="[
              `number__item`,
              { number__item_active: numberIndex === stage.current + 1 },
              { number__item_pass: numberIndex < stage.current + 1 },
            ]"
          v-for="numberIndex in stage.current + 1"
          :key="`number_${numberIndex}`"
          @click="goToStep(numberIndex)"
          v-html="numberIndex"
        />
      </div>
      <div class="switcher">
        <div class="switcher__title" v-html="currentContent.title"/>
        <div class="btns">
          <button
            :class="[
                'btn',
                { btn_active: switchBtn.id === currentObject.chosen },
              ]"
            v-for="(switchBtn, switchBtnIndex) in currentContent.switch"
            :key="`switch_${switchBtnIndex}`"
            @click="
                setProp('chosen', switchBtn.id);
                stage.id === 'therapy'
                  ? setProp('therapyName', switchBtn.name)
                  : null;
                scrollToId('scroll1', switchBtn.name)
              "
            v-html="switchBtn.name"
          />
        </div>
      </div>


      <div v-if="currentObject.chosen !== null" class="drug">

        <div
          v-if="
                  !(
                    currentObject.chosen === null ||
                    currentObject.chosen === 'no'
                  )
                "
          class="drug__content"

        >
          <div id="scroll1" class="drug__title" v-html="currentContent.subtitle"/>
          <div class="btns">
            <button
              v-if="
                      drugBtn.combination === currentObject.chosen ||
                      !drugBtn.combination
                    "
              :class="[
                      'btn',
                      { btn_active: drugBtn.id === currentObject.drug },
                    ]"
              v-for="(drugBtn, drugBtnIndex) in currentContent.drug"
              :key="`drug_${drugBtnIndex}`"
              @click="
                      setProp('drug', drugBtn.id);
                      setProp('prices', drugBtn.prices);
                      setProp('altPrices', drugBtn.altPrices);
                      setProp('drugName', drugBtn.name);
                      setProp('medicalName', drugBtn.medicalName);
                      setProp('calculatorName', drugBtn.calculatorName);
                    "
              v-html="drugBtn.name"
            />
          </div>
        </div>

        <button
          id="next"
          :class="[
                `next`,
                { next_active: stage.activated },
                {
                  next_disabled: isNextDisabled,
                },
              ]"
          :disabled="isNextDisabled"
          @click="goNextStage"
          v-html="currentContent.nextBtn"
        />
      </div>

    </div>

    <!-- Configurator -->
    <div
      v-if="stage.id === 'configurator' && !result.current"
      class="configurator"
    >
      <h1 class="stage__title">Порівняння вартості терапії:<br>Тева та аналоги</h1>
      <div class="number">
        <button
          :class="[
              `number__item`,
     { number__item_active: numberIndex === stage.current + 1 },
              { number__item_pass: numberIndex < stage.current + 1 },
            ]"
          v-for="numberIndex in stage.current + 1"
          :key="`number_${numberIndex}`"
          @click="goToStep(numberIndex)"
          v-html="numberIndex"
        />
      </div>

      <div class="configurator__title" v-html="t.stages[stage.id].title"/>
      <div
        class="configurator__subtitle"
        v-html="t.stages[stage.id].subtitle"
      />
      <div class="bars">
        <div
          v-for="(id, index) in objectNames"
          v-if="
              getObject(id).chosen !== 'no' &&
              getObject(id).chosen &&
              id !== 'temporaryStorage'
            "
          :key="`bar_${id}`"
          :class="[`bar bar_${id} bar_${index}`, { bar_down: popup.current }]"
        >
          <div class="bar__title" v-html="t.stages[id].shortTitle"/>
          <div class="bar__wrapper">
            <div class="bar__wrapper-custom">
              <div
                class="bar__therapy"
                v-if="id === 'therapy'"
                v-html="getObject(id).therapyName"
              />
              <ArrowIco v-if="id === 'therapy'"/>
              <div class="bar__drug" v-html="getObject(id).drugName"/>
            </div>
            <button class="bar__btn" @click="openPopup(id)">
              <PenIco :isActive="popup.activated === id"/>
            </button>
          </div>
        </div>
      </div>

      <button
        id="configurator__btn"
        :class="[
            'configurator__btn',
            { configurator__btn_active: result.activated },
          ]"
        @click="goToResult"
        v-html="t.stages[stage.id].btn"
      />


      <div v-if="popup.current" class="popup__wrapper">
        <div class="popup">
          <button class="popup__close" @click="openPopup(null)">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="40" height="40" rx="20" fill="#00A03B"/>
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M27.6457 27.641C27.0205 28.2662 26.0068 28.2662 25.3816 27.641L12.6056 14.865C11.9804 14.2398 11.9804 13.2261 12.6056 12.6009C13.2308 11.9757 14.2445 11.9757 14.8697 12.6009L27.6457 25.3769C28.2709 26.0021 28.2709 27.0158 27.6457 27.641Z"
                    fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M27.6457 12.5788C28.2709 13.204 28.2709 14.2176 27.6457 14.8428L14.8697 27.6188C14.2445 28.244 13.2309 28.244 12.6057 27.6188C11.9805 26.9936 11.9805 25.9799 12.6057 25.3547L25.3816 12.5788C26.0068 11.9536 27.0205 11.9536 27.6457 12.5788Z"
                    fill="white"/>
            </svg>
          </button>
          <div class="popup__add-scroll">
            <div
              class="popup__title"
              v-html="t.stages[popup.current].shortTitle"
            />
            <div class="popup__drugs">
              <div class="popup__btns">
                <button
                  v-if="
                      drugBtn.combination === temporaryStorage.chosen ||
                      !drugBtn.combination
                    "
                  :class="[
                      'btn',
                      {
                        btn_active: drugBtn.id === temporaryStorage.drug,
                      },
                    ]"
                  v-for="(drugBtn, drugBtnIndex) in t.stages[popup.current]
                      .drug"
                  :key="`popup_drug_${drugBtnIndex}`"
                  @click="
                      setPropInTemporary('drug', drugBtn.id);
                      setPropInTemporary('prices', drugBtn.prices);
                      setPropInTemporary('altPrices', drugBtn.altPrices);
                      setPropInTemporary('drugName', drugBtn.name);
                      setPropInTemporary('medicalName', drugBtn.medicalName);
                      setPropInTemporary(
                        'calculatorName',
                        drugBtn.calculatorName
                      );
                    "
                  v-html="drugBtn.name"
                />
              </div>
            </div>
            <button
              :class="['apply', { apply_active: popup.applied }]"
              @click="applyPopup"
              v-html="t.stages.configurator.popup.btn"
            />
          </div>
        </div>
      </div>

    </div>

    <div v-if="result.current" class="result">
      <div class="result__title" v-html="t.stages.result.title"/>
      <div class="result__subtitle" v-html="t.stages.result.subtitle"/>
      <div class="mode">
        <button
          :class="['btn btn-desk', { btn_active: countMode.current === btnIndex }]"
          v-for="(btn, btnIndex) in t.stages.result.mode"
          :key="`mode_btn_${btnIndex}`"
          @click="countMode.current = btnIndex"
          v-html="btn"
        />


        <swiper ref="mySwiper" :options="swiperOptions" class="mobile-swiper">

          <swiper-slide
            v-for="(btn, btnIndex) in t.stages.result.mode" :key="btnIndex">
            <button
              :class="['btn btn-mobile', { btn_active: countMode.current === btnIndex }]"
              :key="`mode_btn_${btnIndex}`"
              @click="countMode.current = btnIndex; slideTo(btnIndex)"
              v-html="btn"
            />
          </swiper-slide>

          <div class="swiper-button  swiper-button-prev"
               slot="button-prev">
            <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 2L2 9L8 16" stroke="#00A03B" stroke-width="3" stroke-linecap="round"/>
            </svg>
          </div>
          <div class="swiper-button  swiper-button-next"
               slot="button-next">
            <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 16L8 9L2 2" stroke="#00A03B" stroke-width="3" stroke-linecap="round"/>
            </svg>


          </div>

        </swiper>
      </div>
      <div class="table">
        <div class="table__row table__header">
          <div
            :class="`table__item table__header-item table__header-item_${headerItemIndex}`"
            v-for="(headerItem, headerItemIndex) in t.stages.result.table"
            :key="`table_header_${headerItemIndex}`"
            v-html="headerItem"
          />
        </div>
        <div
          :class="`table__row table__drug table__drug_${drugIndex}`"
          v-for="(drug, drugIndex) in calculationResults"
          :key="`table_drug_${drugIndex}`"
        >
          <div class="table__item drug__title" v-html="drug.calculatorName"/>
          <button
            :class="[
                `table__item drug__price`,
                { drug__price_active: packPopup.current === drugIndex },
              ]"
            @click="openPackPopup(drugIndex)"
            v-html="
                drug.prices[countMode.current] === 0
                  ? '0,00'
                  : drug.prices[countMode.current]
                      .toFixed(2)
                      .replace('.', ',') + ''
              "
          />
          <div
            class="table__item drug__altPrice"
            v-html="
                drug.altPrices[countMode.current] === 0
                  ? '0,00'
                  : drug.altPrices[countMode.current]
                      .toFixed(2)
                      .replace('.', ',') +
                    ` ${
                      drug.drug === 'lisinopril' ||
                      drug.drug === 'ramipril' ||
                      drug.drug === 'klopidogrel'
                        ? '***'
                        : ''
                    }`
              "
          />
        </div>
        <div class="table__row table__summary">
          <div
            class="table__item table__summary"
            v-html="t.stages.result.summary"
          />
          <div
            class="table__item table__price table__price_1"
            v-html="summary.prices[countMode.current]"
          />
          <div
            class="table__item table__price table__price_2"
            v-html="summary.altPrices[countMode.current]"
          />
        </div>
      </div>

      <div class="footer">
        <div class="footer__list">
          <div
            class="footer__item"
            v-for="(footerItem, footerItemIndex) in t.stages.result.footer"
            :key="`footer_item_${footerItemIndex}`"
            v-html="footerItem"
          />
        </div>
        <button
          :class="['btn', { btn_active: this.reset.activated }]"
          v-html="t.stages.result.btn"
          @click="resetObjects"
        />
      </div>
    </div>


    <div class="links" v-if="!result.current">
      <p>іАПФ — інгібітор ангіотензин-перетворюючого ферменту</p>
      <p>БРА — блокатор рецепторів ангіотензину ІІ</p>
      <p>БАБ — бета-адреноблокатор</p>
      <p>БКК — блокатор кальцієвих каналів</p>
    </div>
    <div
      v-if="packPopup.current !== null"
      class="packPopup"
      @click="openPackPopup(null)"
    >
      <div class="packPopup__wrapper">
        <button class="packPopup__close" @click="openPackPopup(null)">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="#00A03B"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M27.6457 27.641C27.0205 28.2662 26.0068 28.2662 25.3816 27.641L12.6056 14.865C11.9804 14.2398 11.9804 13.2261 12.6056 12.6009C13.2308 11.9757 14.2445 11.9757 14.8697 12.6009L27.6457 25.3769C28.2709 26.0021 28.2709 27.0158 27.6457 27.641Z"
                  fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M27.6457 12.5788C28.2709 13.204 28.2709 14.2176 27.6457 14.8428L14.8697 27.6188C14.2445 28.244 13.2309 28.244 12.6057 27.6188C11.9805 26.9936 11.9805 25.9799 12.6057 25.3547L25.3816 12.5788C26.0068 11.9536 27.0205 11.9536 27.6457 12.5788Z"
                  fill="white"/>
          </svg>
        </button>
        <div class="packPopup__add-scroll">
          <img
            :src="
              require(`@/assets/img/calc/${
                calculationResults[packPopup.current].drug
              }.png`)
            "
            alt="pack"
            class="packPopup__pack"
          />
          <div class="packPopup__content">
            <div
              class="packPopup__title"
              v-html="calculationResults[packPopup.current].medicalName"
            />
            <div
              class="packPopup__price"
              v-html="
                calculationResults[packPopup.current].prices[countMode.current]
                  ? calculationResults[packPopup.current].prices[
                      countMode.current
                    ]
                      .toFixed(2)
                      .replace('.', ',')
                  : '0,00'
              "
            />
            <div
              class="packPopup__footer"
              v-html="t.stages.result.packPopup[countMode.current]"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import mixins from "@/app-helper/mixins";
import text from "@/components/calc/ua/index"

import PenIco from "@/components/calc/svg/PenIco.vue";
import ArrowIco from "@/components/calc/svg/ArrowIco.vue";

export default {
  components: {
    PenIco,
    ArrowIco,
  },

  data() {
    return {
      t: text.content,
      objectNames: [
        "stage",
        "therapy",
        "inhibitorDiuretic",
        "diuretric",
        "inhibitor",
        "bab",
        "bkk",
        "antiTromboletic",
        "hipolipidemic",
        "countMode",
        "popup",
        "result",
        "reset",
        "temporaryStorage",
        "stepTracker",
        "summary",
        "packPopup",
      ],
      calculationResults: [],
      stage: {
        id: "therapy",
        current: 0,
        activated: false,
        all: 7,
      },
      therapy: {
        chosen: null,
        therapyName: null,
        drug: null,
        drugName: null,
        medicalName: null,
        calculatorName: null,
        prices: [],
        altPrices: [],
      },
      inhibitorDiuretic: {
        chosen: null,
        drug: null,
        drugName: null,
        medicalName: null,
        calculatorName: null,
        prices: [],
        altPrices: [],
      },
      diuretric: {
        chosen: null,
        drug: null,
        drugName: null,
        medicalName: null,
        calculatorName: null,
        prices: [],
        altPrices: [],
      },
      inhibitor: {
        chosen: null,
        drug: null,
        drugName: null,
        medicalName: null,
        calculatorName: null,
        prices: [],
        altPrices: [],
      },
      bab: {
        chosen: null,
        drug: null,
        drugName: null,
        medicalName: null,
        calculatorName: null,
        prices: [],
        altPrices: [],
      },
      bkk: {
        chosen: null,
        drug: null,
        drugName: null,
        medicalName: null,
        calculatorName: null,
        prices: [],
        altPrices: [],
      },
      antiTromboletic: {
        chosen: null,
        drug: null,
        drugName: null,
        medicalName: null,
        calculatorName: null,
        prices: [],
        altPrices: [],
      },
      hipolipidemic: {
        chosen: null,
        drug: null,
        drugName: null,
        medicalName: null,
        calculatorName: null,
        prices: [],
        altPrices: [],
      },
      popup: {
        current: false,
        activated: false,
        applied: false,
      },
      result: {
        current: false,
        activated: false,
      },
      countMode: {
        current: 0,
        activated: false,
      },
      reset: {
        current: false,
        activated: false,
      },
      temporaryStorage: {},
      stepTracker: [],
      summary: {
        prices: [],
        altPrices: [],
      },
      packPopup: {
        current: null,
        activated: null,
      },
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 16,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          768: {
            spaceBetween: 4,
            slidesPerView: 'auto',
          },
          540: {
            spaceBetween: 4,
            slidesPerView: 'auto',
          },
          320: {
            spaceBetween: 4,
            slidesPerView: 'auto',
          }
        }
      }
    };
  },


  mounted() {
    this.stepTracker.push(this.stage.id);
  },
  computed: {
    currentContent() {
      return this.t.stages[this.stage.id];
    },
    currentObject() {
      return this[this.stage.id];
    },
    isNextDisabled() {
      return !(
        this.currentObject.chosen === "no" ||
        (this.currentObject.chosen === "yes" && this.currentObject.drug) ||
        (this.currentObject.chosen !== "no" &&
          this.currentObject.chosen !== "yes" &&
          this.currentObject.chosen !== null &&
          this.currentObject.drug)
      );
    },
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  methods: {
    slideTo(id) {
      console.log(id)
      console.log(this.swiper)
      if (this.swiper.activeIndex !== id) {
        this.swiper.slideTo(id, 200, false)
      }
    },

    initializeObjectHandler(targetObject, name) {
      if (name === "stage" || name === "countMode") {
        targetObject["current"] = 0;
        targetObject["activated"] = false;

        if (name === "stage") {
          targetObject["id"] = "therapy";
        }
      } else if (name === "popup" || name === "caculate") {
        targetObject["current"] = false;
        targetObject["activated"] = false;
      } else if (name === "result") {
        targetObject["current"] = false;
        targetObject["activated"] = false;
      } else if (name === "temporaryStorage") {
        targetObject = {};
      } else if (name === "stepTracker") {
        this.stepTracker = ["therapy"];
      } else if (name === "summary") {
        targetObject["prices"] = [];
        targetObject["altPrices"] = [];
      } else if (name === "reset") {
        null;
      } else if (name === "calculationResults") {
        targetObject = [];

        //check if this.calculationResults array is empty
        if (this.calculationResults.length > 0) {
          console.warn(
            `calculationResults array is not empty, it should be empty in initializeObjectHandler`
          );
        }
      } else {
        targetObject["chosen"] = null;
        targetObject["drug"] = null;
        targetObject["drugName"] = null;
        targetObject["medicalNeme"] = null;
        targetObject["calculatorName"] = null;
        targetObject["prices"] = [];
        targetObject["altPrices"] = [];

        if (name === "therapy") {
          targetObject["therapyName"] = null;
        }
      }
    },

    resetObjects() {
      this.reset.activated = true;

      // setTimeout(() => {
      this.reset.current = true;

      this.objectNames.forEach((name) => {
        this.initializeObjectHandler(this[`${name}`], name);
      });

      this.calculationResults = [];

      this.reset.current = false;
      this.reset.activated = false;
      // }, 150);

      this.scrollToTop()
    },

    setProp(prop, value) {
      if (prop in this[this.stage.id]) {
        this[this.stage.id][prop] = value;
      } else {
        console.warn(`Property ${prop} does not exist in the object.`);
      }
    },

    setPropInTemporary(prop, value) {
      if (prop in this.temporaryStorage) {
        this.temporaryStorage[prop] = value;
      } else {
        console.warn(`Property ${prop} does not exist in the object.`);
      }
    },

    getObject(id) {
      return this[id];
    },

    goNextStage() {
      let newStageId = "";

      console.log(this.stage.current)


      const inhibitorDiureticCondition =
        this.stage.current === 0 &&
        (this.therapy.chosen === "bab" || this.therapy.chosen === "bkk");

      const diuretricCondition =
        (this.stage.current === 0 &&
          (this.therapy.chosen === "iapf" || this.therapy.chosen === "bra")) ||
        (this.stage.current === 1 &&
          (this.therapy.chosen === "bab" || this.therapy.chosen === "bkk") &&
          this.inhibitorDiuretic.chosen === "no");

      const inhibitorCondition =
        this.stage.current === 2 &&
        (this.therapy.chosen === "bab" || this.therapy.chosen === "bkk") &&
        this.inhibitorDiuretic.chosen === "no";

      const babCondition =
        (this.stage.current === 0 &&
          (this.therapy.chosen === "iapfDiuretic" ||
            this.therapy.chosen === "braDiuretic")) ||
        (this.stage.current === 1 &&
          (this.therapy.chosen === "iapf" || this.therapy.chosen === "bra")) ||
        (this.stage.current === 1 &&
          this.therapy.chosen === "bkk" &&
          this.inhibitorDiuretic.drug) ||
        (this.stage.current === 3 &&
          this.therapy.chosen === "bkk" &&
          this.inhibitorDiuretic.chosen === "no");

      const bkkCondition =
        (this.stage.current === 1 &&
          (this.therapy.chosen === "iapfDiuretic" ||
            this.therapy.chosen === "braDiuretic")) ||
        (this.stage.current === 1 &&
          this.therapy.chosen === "bab" &&
          this.inhibitorDiuretic.drug) ||
        (this.stage.current === 2 &&
          (this.therapy.chosen === "iapf" || this.therapy.chosen === "bra")) ||
        (this.stage.current === 3 &&
          this.therapy.chosen === "bab" &&
          !this.bkk.chosen);

      const antiTromboleticCondition =
        ((this.therapy.chosen !== "bkk" && this.bkk.chosen) ||
          (this.therapy.chosen === "bkk" && this.bab.chosen)) &&
        !this.antiTromboletic.chosen;

      const hipolipidemicCondition =
        !!this.antiTromboletic.chosen && !!!this.hipolipidemic.chosen;

      const configuratorCondition = !!this.hipolipidemic.chosen;

      if (inhibitorDiureticCondition) newStageId = "inhibitorDiuretic";
      else if (diuretricCondition) newStageId = "diuretric";
      else if (inhibitorCondition) newStageId = "inhibitor";
      else if (babCondition) newStageId = "bab";
      else if (bkkCondition) newStageId = "bkk";
      else if (antiTromboleticCondition) newStageId = "antiTromboletic";
      else if (hipolipidemicCondition) newStageId = "hipolipidemic";
      else if (configuratorCondition) newStageId = "configurator";

      this.stage.activated = !this.stage.activated;

      // setTimeout(() => {
      this.stage.current += 1;
      this.stage.id = newStageId;
      this.stepTracker.push(newStageId);
      this.stage.activated = !this.stage.activated;
      // }, 150);


      this.scrollToTop()
    },

    goToStep(stepNumber) {
      this.stage.id = this.stepTracker[stepNumber - 1];
      this.stage.current = stepNumber - 1;

      this.stepTracker.forEach((stepId, stepIdIndex) => {
        if (stepIdIndex >= stepNumber - 1 && stepId !== "configurator") {
          this[stepId].chosen = null;
          this[stepId].drug = null;
          this[stepId].drugName = null;
          this[stepId].medicalName = null;
          this[stepId].calculatorName = null;
          this[stepId].prices = [];
          this[stepId].altPrices = [];
        }
      });

      this.stepTracker = this.stepTracker.slice(0, stepNumber);

      this.scrollToTop()
    },

    goToResult() {
      this.result.activated = true;

      // setTimeout(() => {
      this.result.current = true;
      this.calculate();
      // }, 150);

      console.log(this.calculationResults)

      this.scrollToTop()
    },

    openPopup(id) {
      this.popup.activated = id;

      if (id === null) {
        this.temporaryStorage = {};
      } else {
        this.temporaryStorage = {...this[id]};
      }

      // setTimeout(() => {
      this.popup.current = id;
      // }, 150);
    },

    openPackPopup(id) {
      this.packPopup.activated = id;

      // setTimeout(() => {
      this.packPopup.current = id;
      // }, 150);
    },

    applyPopup() {
      this[this.popup.current] = {...this.temporaryStorage};
      this.temporaryStorage = {};

      this.popup.applied = true;

      // setTimeout(() => {
      this.popup.applied = false;
      this.popup.activated = false;
      this.popup.current = false;
      // }, 150);
    },

    calculate() {
      const priceAccumulator = [[], [], [], []];
      const altPriceAccumulator = [[], [], [], []];

      this.objectNames.forEach((name) => {
        let calculatedObject = {};

        if (this[name].chosen && this[name].chosen !== "no") {
          calculatedObject["drug"] = this[name].drug;
          calculatedObject["drugName"] = this[name].drugName;
          calculatedObject["medicalName"] = this[name].medicalName;
          calculatedObject["calculatorName"] = this[name].calculatorName;
          calculatedObject["prices"] = [];
          calculatedObject["altPrices"] = [];

          this[name].prices.forEach((price, index) => {
            if (price || price === 0) calculatedObject["prices"].push(price);
            // if (price === 0 && index !== 0)
            //   calculatedObject["prices"].push(this[name].prices[index - 1]);
          });

          this[name].altPrices.forEach((price, index) => {
            if (price || price === 0) calculatedObject["altPrices"].push(price);
            // if (price === 0 && index !== 0)
            //   calculatedObject["altPrices"].push(
            //     this[name].altPrices[index - 1]
            //   );
          });

          let pricesBufferArry = [];
          let altPricesBufferArry = [];

          calculatedObject.prices.forEach((price) =>
            pricesBufferArry.push(price * 12)
          );
          calculatedObject.prices = [
            ...calculatedObject.prices,
            ...pricesBufferArry,
          ];

          calculatedObject.altPrices.forEach((price) =>
            altPricesBufferArry.push(price * 12)
          );
          calculatedObject.altPrices = [
            ...calculatedObject.altPrices,
            ...altPricesBufferArry,
          ];

          this.calculationResults.push(calculatedObject);
        }
      });

      this.calculationResults.forEach((drug) => {
        drug.prices.forEach((price, index) => {
          priceAccumulator[index].push(price);
        });

        drug.altPrices.forEach((price, index) => {
          altPriceAccumulator[index].push(price);
        });
      });

      priceAccumulator.forEach((priceArray) => {
        this.summary.prices.push(
          priceArray
            .reduce((acc, price) => acc + price, 0)
            .toFixed(2)
            .replace(".", ",")
        );
      });

      altPriceAccumulator.forEach((altPriceArray) => {
        this.summary.altPrices.push(
          altPriceArray
            .reduce((acc, price) => acc + price, 0)
            .toFixed(2)
            .replace(".", ",")
        );
      });

      this.scrollToTop()
    },

    scrollToTop() {
      window.scrollTo(0, 0);

    },

    scrollToId(id, btnName) {
      if (btnName !== "Ні") {

        setTimeout(() => {
          let element = document.getElementById(id);
          let headerOffset = 80 // Replace with your header height
          let elementPosition = element.getBoundingClientRect().top;
          let offsetPosition = elementPosition - headerOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }, 150)
      } else if (btnName === "Ні") {
        setTimeout(() => {
          let element = document.getElementById("next");
          // let headerOffset = document.getElementById("footer-wrapper").getBoundingClientRect().height // Replace with your header height
          // let elementPosition = element.getBoundingClientRect().top;
          // let offsetPosition = elementPosition - headerOffset;

          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
          // window.scrollTo({
          //   top: offsetPosition,
          //   behavior: 'smooth'
          // });
        }, 150)
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/fonts.css";
@import "../assets/style/vars";

#content {
  min-height: calc(100vh);
  min-height: calc((var(--vh, 1vh) * 100) - var(--height_content));

  width: 100%;
  margin: 0 auto;

  padding: 40px 20px 48px 20px;

  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    padding: 20px 20px 20px 20px;
  }

  .swiper-container {
    width: 100%;
    position: relative;
    padding-left: 18px;
  }

  .swiper-button ::v-deep {
    top: 50%;
    transform: translateY(-50%);
    height: calc(100% + 1px);

  }

  .swiper-button svg ::v-deep {
    position: relative;
    z-index: 2;

  }

  .swiper-button:after ::v-deep {

    display: none !important;

  }

  .swiper-button-prev ::v-deep {
    left: 0;
    display: flex;
    justify-content: flex-start;

  }

  .swiper-button-prev:before ::v-deep {
    content: '';
    width: 25px;
    height: 100%;

    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    pointer-events: none;

    background: linear-gradient(-90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 41.11%, #FFF 48.02%, #FFF 78.66%);

  }

  .swiper-button-disabled ::v-deep {
    display: none !important;
  }

  .swiper-button-next ::v-deep {
    right: 0;
    display: flex;
    justify-content: flex-end;
    //background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) -5.74%, #FFF 41.11%, #FFF 48.02%, #FFF 78.66%);
  }

  .swiper-button-next:before ::v-deep {
    content: '';
    width: 73px;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;

    background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 41.11%, #FFF 48.02%, #FFF 78.66%);
  }


  .swiper-slide ::v-deep {
    width: 100%;
    max-width: 140px;
  }

  .swiper-slide:nth-child(2) ::v-deep {
    width: 100%;
    max-width: 198px;
  }

  .swiper-slide:nth-child(3) ::v-deep {
    width: 100%;
    max-width: 140px;
  }

  .swiper-slide:nth-child(4) ::v-deep {
    width: 100%;
    max-width: 169px;
  }

  .mobile-swiper {
    display: none;
    @media screen and (max-width: 768px) {
      display: flex;
    }
  }

  * button {
    background: none;
    outline: none;
    border: none;
  }

  .number {
    display: flex;
    //margin: 0 auto 48px;

    &__item {
      cursor: pointer;
      position: relative;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 50%;
      margin-right: 40px;

      border: 1px solid var(--Borders, #E1E1E1);
      background: #FFF;


      color: #8E8E8E;
      font-family: $B;
      font-size: 18px;
      font-style: normal;

      line-height: normal;

      @media screen and (max-width: 1024px) {
        font-size: 14px;
        width: 32px;
        height: 32px;
        margin-right: 32px;
      }

      @media screen and (max-width: 600px) {
        margin-right: 12px;
      }

      &:not(.number__item_active) {
        @media screen and (min-width: 1024px) {
          &:hover {
            background: rgba(0, 160, 59, 0.10);
          }
        }
      }

      &_active {
        background-color: #00a03b;
        color: #ffffff;
        border: 1px solid var(--Primary, #00A03B);

        &::after {
          background-color: #00a03b !important;
        }
      }

      &_pass {
        color: var(--Primary, #00A03B);
        border: 1px solid var(--Primary, #00A03B);

        &::after {
          background-color: #00a03b !important;
        }
      }

      &::after {
        position: absolute;
        content: "";
        left: 39px;
        width: 41px;
        height: 1px;
        background: #E1E1E1;
        @media screen and (max-width: 1024px) {
          left: 31px;
          width: 33px;
        }
        @media screen and (max-width: 600px) {
          left: 31px;
          width: 13px;
        }
      }

      &:last-child {
        margin: 0;

        &::after {
          display: none;
        }
      }
    }
  }

  .stage {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__title {
      color: var(--teva-grey, #424242);
      text-align: center;

      font-family: $EB;
      font-size: 48px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
      margin-bottom: 56px;
      @media screen and (max-width: 1024px) {
        font-size: 24px;
        margin-bottom: 32px;
      }
    }

    .switcher {
      max-width: 820px;
      width: 100%;

      &__title {

        margin-top: 56px;
        margin-bottom: 28px;
        display: flex;
        justify-content: center;
        align-items: center;


        color: var(--teva-grey, #424242);
        text-align: center;

        /* desktop/h3 */
        font-family: $B;
        font-size: 32px;
        font-style: normal;

        line-height: normal;
        @media screen and (max-width: 1024px) {
          margin-top: 32px;
          margin-bottom: 16px;
          font-size: 18px;
        }

      }
    }

    .drug {
      //height: 484px;
      display: flex;
      flex-direction: column;
      align-items: center;

      max-width: 820px;
      width: 100%;

      @media screen and (max-width: 1024px) {
      }

      &__content {
        width: 100%;
      }

      &__title {

        color: var(--Primary, #00A03B);
        text-align: center;
        font-family: $B;
        font-size: 24px;
        font-style: normal;

        line-height: 44px; /* 183.333% */

        margin-bottom: 20px;
        margin-top: 56px;


        @media screen and (max-width: 1024px) {
          font-size: 18px;
          margin-top: 40px;

        }
      }
    }

    .btns {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;

      // margin-bottom: 80px;

      margin: 0 auto 0 auto;

      @media screen and (max-width: 1024px) {
        margin: 0 auto 0 auto;
      }

      .btn {
        max-width: 262px;
        width: 100%;
        // height: 108px;

        padding: 20px 10px;

        border-radius: 100px;
        border: 1px solid var(--Primary, #00a03b);

        display: flex;
        justify-content: center;
        align-items: center;

        color: var(--Primary, #00a03b);

        font-family: $R;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px; /* 106.806% */

        z-index: 11;
        cursor: pointer;

        @media screen and (max-width: 1024px) {
          padding: 18px 10px;
          font-size: 18px;
        }

        &:not(.btn_active) {
          @media screen and (min-width: 1024px) {
            &:hover {
              background: rgba(0, 160, 59, 0.10);
            }
          }

          &:active {
            background: rgba(0, 160, 59, 0.10);
          }
        }

        &_active {
          background-color: var(--Primary, #00a03b);
          color: #ffffff;
        }
      }
    }

    .next {
      //position: absolute;
      //bottom: 180px;
      display: flex;
      padding: 23px 58px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 100px;
      background: rgba(0, 160, 59, 0.1);

      color: var(--Primary, #00A03B);
      font-family: $B;
      font-size: 20px;
      font-style: normal;

      line-height: 44px;

      border: none !important;
      outline: none;
      overflow: hidden;
      position: relative;
      z-index: 2;
      margin-top: 56px;

      @media screen and (max-width: 1024px) {
        padding: 18px 58px;
        font-size: 18px;
        margin-top: 40px;
      }

      &_active {
        color: var(--Primary, #00a03b);
        background: rgba(0, 160, 59, 0.2);
      }

      &:after {
        content: 'Далі';
        opacity: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #00A03B;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        color: var(--Primary, #00A03B);
        font-family: $B;
        font-size: 20px;
        font-style: normal;

        line-height: 44px;

        @media screen and (max-width: 1024px) {
          font-size: 18px;
          line-height: 25px;
        }
      }

      &:not(.next_disabled):not(.next_disabled) {
        cursor: pointer;
        @media screen and (min-width: 1024px) {
          &:hover {
            &:after {
              opacity: 1;
              color: white;
            }

            color: white;
          }
        }

        &:active {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #00A03B;
          color: white;
        }
      }

      &_disabled {
        opacity: 0.5;
        background: var(--Gray-BG, #eee);
        color: var(--Text-Secondary, #8e8e8e);
      }
    }
  }

  .configurator {
    //position: absolute;
    //left: 0;
    //top: 0;
    //width: 100%;
    //height: 100%;
    //padding: 100px 140px;

    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      color: var(--Text-Primary, #424242);
      text-align: center;

      font-family: $B;
      font-size: 32px;
      font-style: normal;

      line-height: normal;
      margin-bottom: 16px;
      margin-top: 56px;


      @media screen and (max-width: 1024px) {
        margin-top: 32px;
        font-size: 18px;
      }

    }

    &__subtitle {
      color: var(--teva-grey, #424242);
      text-align: center;
      font-family: $R;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      margin-bottom: 28px;

      ::v-deep strong {
        font-family: $B;
      }

      @media screen and (max-width: 1024px) {
        font-size: 14px;
        margin-bottom: 16px;
      }
    }

    .bars {
      display: flex;
      flex-direction: column;
      margin-bottom: 70px;

      max-width: 820px;
      width: 100%;

      @media screen and (max-width: 1024px) {
        margin-bottom: 8px;
      }

      .bar {
        width: 100%;
        display: flex;
        padding: 16px 28px;
        align-items: center;

        border-radius: 19px;

        background: #fff;

        /* Card shadow */
        box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.07);

        z-index: 11;

        @media screen and (max-width: 1024px) {
          flex-flow: row wrap;
          align-items: flex-start;
          padding: 12px 20px;

        }

        &__btn {
          outline: none;
          background: none;
          border: none;
          cursor: pointer;

          svg {
            margin-right: 0 !important;
            display: block;
            width: 37px;
            height: auto;
          }

          @media screen and (min-width: 1024px) {
            &:hover {
              svg ::v-deep {

                rect {
                  fill: #00A03B;
                  stroke: #00A03B;
                }

                path {
                  fill: white;
                }
              }
            }
          }

          &:active {
            svg ::v-deep {

              rect {
                fill: #00A03B;
                stroke: #00A03B;
              }

              path {
                fill: white;
              }
            }
          }

          svg ::v-deep {
            width: 39px;
            height: auto;
            display: block;
          }
        }

        &_down {
          z-index: 1;
        }

        &:not(:last-child) {
          margin-bottom: 20px;

          @media screen and (max-width: 1024px) {
            margin-bottom: 8px;
          }
        }

        &__wrapper {
          display: flex;
          align-items: center;
          margin: 0 0 0 auto;
          @media screen and (max-width: 1024px) {
            width: 100%;
            margin: 0;
            justify-content: flex-end;
            justify-content: space-between;
          }

          svg {
            margin: 0 11px;
          }

          &-custom {
            display: flex;
            justify-content: flex-end;

            @media screen and (max-width: 1024px) {
              flex-flow: row wrap;
              justify-content: flex-start;
            }

            > svg {
              margin: 0 11px;
              height: auto;
              display: block;

              @media screen and (max-width: 1024px) {
                min-width: 19px;
                width: 19px;
              }
            }

            svg {
              margin: 0 11px;
            }
          }
        }

        &__title {
          color: var(--Primary, #00a03b);

          /* Footnote */
          font-family: $R;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px;

          @media screen and (max-width: 1024px) {
            font-size: 16px;
            line-height: 110%;
            margin-bottom: 8px;
          }
        }

        &__therapy {

          /* Footnote */
          font-family: $R;
          color: var(--teva-grey, #424242);
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px;

          @media screen and (max-width: 1024px) {
            font-size: 16px;
            line-height: 110%;
          }
        }

        &__drug {

          //display: flex;
          //max-width: 820px;
          //width: 100%;
          color: var(--Text-Primary, #424242);

          /* Footnote Bold */
          font-family: $B;
          font-size: 20px;
          font-style: normal;

          line-height: 23px; /* 119.708% */


          @media screen and (max-width: 1024px) {
            font-size: 16px;
            line-height: 110%; /* 17.6px */
          }
        }
      }
    }

    &__btn {
      display: block;
      padding: 23px 58px;

      border-radius: 100px;
      border: 1px solid var(--Primary, #00A03B);
      background: var(--Primary, #00A03B);

      color: var(--White, #FFF);
      font-family: $B;
      font-size: 20px;
      font-style: normal;

      line-height: 25px;

      margin: 28px auto 0 auto;

      position: relative;
      overflow: hidden;

      cursor: pointer;

      @media screen and (max-width: 1024px) {
        font-size: 18px;
        padding: 18px 58px;
        line-height: 25px;
      }

      &:after {
        content: 'Розрахувати';
        opacity: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #00A03B;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        color: var(--Primary, #00A03B);
        font-family: $B;
        font-size: 20px;
        font-style: normal;

        line-height: 44px;
        @media screen and (max-width: 1024px) {
          font-size: 18px;
          line-height: 25px;
        }
      }

      @media screen and (min-width: 1024px) {
        &:hover {
          &:after {
            opacity: 1;
            color: white;
          }

          color: white;
        }
      }

      &:active {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #00A03B;
        color: white;
      }

      &_active {
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(0, 0, 0, 0.2) 100%
        ),
        #00a03b;
      }
    }

    .popup {

      max-width: 820px;
      width: 100%;
      max-height: 100%;

      //left: 142px;
      //top: 100px;
      //width: 1764px;
      //height: 1130px;
      position: relative;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 64px;

      border-radius: 29px;

      background: var(--white, #fff);

      z-index: 10;
      @media screen and (max-width: 1024px) {
        padding: 77px 24px;
      }
      @media screen and (max-height: 501px) and (min-width: 1025px) {
        padding: 20px;
      }
      @media screen and (max-height: 501px) and (max-width: 1024px) {
        padding: 20px;
      }

      &__add-scroll {
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &__drugs {
        width: 100%;
      }

      &__wrapper {
        position: fixed;

        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 9999999;
        padding: 20px;
      }

      &__close {
        position: absolute;
        top: 30px;
        right: 30px;

        background: none;
        outline: none;
        border: none;
        width: 40px;
        height: 40px;
        cursor: pointer;

        @media screen and (max-width: 1024px) {
          width: 32px !important;
          height: 32px !important;

          top: 10px !important;
          right: 10px !important;
          svg {
            width: 32px !important;
            height: 32px !important;
          }
        }
        @media screen and (max-height: 500px) and (min-width: 1025px) {
          width: 32px !important;
          height: 32px !important;

          top: 10px !important;
          right: 10px !important;
          svg {
            width: 32px !important;
            height: 32px !important;
          }
        }


        @media screen and (max-height: 500px) and (max-width: 1024px) {
          width: 32px;
          height: 32px;

          top: 20px;
          right: 20px;
          svg {
            width: 32px;
            height: 32px;
          }
        }

        @media screen and (min-width: 1024px) {
          &:hover {
            svg ::v-deep {

              rect {
                fill: #00A03B;
              }

            }
          }
        }

        &:active {
          svg ::v-deep {

            rect {
              fill: #00A03B;
            }
          }
        }

        ::v-deep svg {
          width: 40px;
          height: 40px;
          display: block;
        }
      }

      &__title {
        color: var(--Text-Primary, #424242);
        text-align: center;


        /* desktop/h2 */
        font-family: $EB;
        font-size: 38px;
        font-style: normal;
        font-weight: 800;
        line-height: 46px; /* 121.053% */

        margin-top: 30px;
        margin-bottom: 48px;
        @media screen and (max-width: 1024px) {
          margin: 0 0 20px 0;
          font-size: 22px;
          line-height: normal;
        }
        @media screen and (max-height: 501px) and (min-width: 1025px) {
          margin-top: 20px;
          margin-bottom: 20px;
        }
        @media screen and (max-height: 501px) and (max-width: 1024px) {
          margin-top: 20px;
          margin-bottom: 20px;
        }

      }

      &__drug-title {
        color: var(--Primary, #00a03b);
        text-align: center;

        /* Body 2 */
        font-family: $R;
        font-size: 44px;
        font-style: normal;
        font-weight: 400;
        line-height: 44px; /* 100% */

        margin-bottom: 48px;


      }

      &__btns {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 16px;

        // margin-bottom: 80px;

        margin: 0 auto 48px;
        @media screen and (max-width: 1024px) {
          margin: 0 auto 40px;
          gap: 8px;
        }
        @media screen and (max-height: 501px) and (min-width: 1025px) {
          margin-bottom: 20px;
        }
        @media screen and (max-height: 501px) and (max-width: 1024px) {
          margin-bottom: 20px;
        }


        .btn {
          max-width: 220px;
          width: 100%;
          // height: 108px;

          padding: 20px 10px;

          border-radius: 100px;
          border: 1px solid var(--Primary, #00a03b);

          display: flex;
          justify-content: center;
          align-items: center;

          color: var(--Primary, #00a03b);

          /* Body 2 */
          font-family: $R;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 44px;
          cursor: pointer;

          @media screen and (max-width: 1024px) {
            font-size: 18px;
            line-height: 44px;
            padding: 18px 10px;
            max-width: 251px;
          }

          &:not(.btn_active) {
            @media screen and (min-width: 1024px) {
              &:hover {
                background: rgba(0, 160, 59, 0.10);
              }
            }

            &:active {
              background: rgba(0, 160, 59, 0.10);
            }
          }

          &_active {
            background-color: var(--Primary, #00a03b);
            color: #ffffff;
          }
        }
      }

      .apply {
        display: flex;
        padding: 23px 58px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 100px;
        background: rgba(0, 160, 59, 0.1);

        color: var(--Primary, #00a03b);
        outline: none;
        border: none;

        font-family: $B;
        font-size: 20px;
        font-style: normal;

        line-height: 44px;
        cursor: pointer;

        position: relative;
        overflow: hidden;

        @media screen and (max-width: 1024px) {
          padding: 15px 58px;
          font-size: 18px;
          line-height: 44px;
        }

        &:after {
          content: 'Зберегти зміни';
          opacity: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #00A03B;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          color: var(--Primary, #00A03B);
          font-family: $B;
          font-size: 20px;
          font-style: normal;

          line-height: 44px;

          @media screen and (max-width: 1024px) {
            font-size: 18px;
            line-height: 25px;
          }
        }

        @media screen and (min-width: 1024px) {
          &:hover {
            &:after {
              opacity: 1;
              color: white;
            }

            color: white;
          }
        }

        &:active {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #00A03B;
          color: white;
        }

        &_active {
          color: var(--Primary, #00a03b);
          background: rgba(0, 160, 59, 0.2);
        }
      }
    }
  }

  .result {
    //position: absolute;
    //left: 0;
    //top: 0;
    //width: 100%;
    //height: 100%;
    max-width: 1032px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    margin: 0 auto;


    &__wrapper {
      display: flex;
      max-width: 1029px;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      border-radius: 32px;
      background: var(--White, #fff);

      /* Card shadow */
      box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.07);

      margin-bottom: 48px;
    }

    &__title {
      color: var(--Text-Primary, #424242);
      text-align: center;

      margin-bottom: 12px;

      /* desktop/h1 */
      font-family: $EB;
      font-size: 48px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 57.6px */

      @media screen and (max-width: 1024px) {
        font-size: 20px;
      }
    }

    &__subtitle {
      color: var(--Text-Primary, #424242);
      text-align: center;
      font-family: $R;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 34px; /* 170% */

      margin-bottom: 56px;

      @media screen and (max-width: 1024px) {
        font-size: 14px;
        line-height: normal;
        margin-bottom: 32px;
      }
    }

    .mode {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;

      @media screen and (max-width: 1024px) {
        margin-bottom: 32px;
      }

      .btn {
        max-width: calc(25% - 8px);
        width: 100%;
        display: flex;
        padding: 16px 11px;
        justify-content: center;
        align-items: center;
        gap: 16px;

        color: var(--Primary, #00a03b);
        text-align: center;

        /* Caption */
        font-family: $R;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; /* 19.8px */

        border-radius: 100px;
        border: 1px solid var(--Primary, #00a03b);
        cursor: pointer;

        @media screen and (max-width: 1024px) {
          font-size: 14px;
          line-height: 110%;
          padding: 12px 16px;
          gap: 4px;
          max-width: calc(25% - 4px);
        }

        &.btn-desk {
          display: flex;
          @media screen and (max-width: 768px) {
            display: none;
          }
        }

        &.btn-mobile {
          width: 100%;
          max-width: 100%;
        }

        &:not(.btn_active) {
          @media screen and (min-width: 1024px) {
            &:hover {
              background: rgba(0, 160, 59, 0.10);
            }
          }

          &:active {
            background: rgba(0, 160, 59, 0.10);
          }
        }

        &_active {
          background: var(--Primary, #00a03b);
          color: #ffffff;
        }
      }
    }

    .table {
      width: 100%;
      display: flex;
      flex-direction: column;
      background: var(--White, #fff);
      overflow: hidden;

      &__header {
        border-top: 1px solid $green;
        border-bottom: 1px solid $green;

        @media screen and (max-width: 1024px) {
          align-items: flex-start !important;
        }
      }

      &__row {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        padding: 20px 29px;

        color: var(--Text-Primary, #424242);

        font-family: $B;
        font-size: 18px;
        font-style: normal;

        line-height: 24px;

        @media screen and (max-width: 1024px) {
          padding: 12px;
          font-size: 13px;
        }

        @media screen and (max-width: 455px) {
          padding: 6px;
          font-size: 12px;
        }

        &:first-child {
          //background: var(--Gray-BG, #eee);
        }

        &:last-child {
          background: var(--Green-BG, #EFF5D7);
        }
      }

      &__item {
        //width: 340px;

        font-family: $B;
        width: calc(20% - 10px);
        margin-right: 10px;

        @media screen and (max-width: 455px) {
          width: calc(25% - 10px);
        }

        &:first-child {
          width: calc(60% - 10px);

          @media screen and (max-width: 455px) {
            width: calc(50% - 10px);
          }

        }

        &:last-child {
          width: calc(20%);
          margin-right: 0;
          @media screen and (max-width: 455px) {
            width: calc(25%);
          }
        }

        ::v-deep .small {
          font-weight: 400;
          font-family: $R;
        }
      }

      &__drug {
        //height: 80px;
      }

      .drug {
        &__title {
          color: var(--teva-green, #00A03B);
          font-family: $R;
          font-size: 18px;
          font-style: normal;
          line-height: 24px; /* 135.861% */
          //text-decoration-line: underline;

          @media screen and (max-width: 1024px) {
            font-size: 13px;
          }

          @media screen and (max-width: 455px) {
            font-size: 12px;
          }
        }

        &__price {
          width: calc(20% - 30px);
          text-decoration-line: underline;

          color: var(--teva-green, #00A03B);
          font-family: $B;
          font-size: 18px;
          font-style: normal;
          line-height: 24px; /* 135.861% */

          margin-right: 30px;
          text-align: left;
          cursor: pointer;

          @media screen and (max-width: 1024px) {
            font-size: 13px;
          }

          @media screen and (min-width: 1024px) {
            &:hover {
              text-decoration: underline;
            }
          }

          @media screen and (max-width: 455px) {
            font-size: 12px;
            width: calc(25% - 30px);
          }

          &:active {
            text-decoration: underline;
          }

          &_active {
            color: #00802f;
          }
        }
      }

      &__header,
      &__summary {
        .table__item:nth-child(2) {
          color: #00a03b;
        }
      }

      &__drug {
        border-bottom: 1px solid var(--Borders, #00A03B);
      }

      :nth-last-child(2) {
        border-bottom: none;
      }
    }

    .footer {
      margin-top: 20px;

      &__item {

        /* Caption 2 */
        color: var(--Text-Secondary, #8E8E8E);
        font-family: $R;
        font-size: 14px;
        font-style: normal;
        line-height: 120%; /* 16.8px */
        margin-bottom: 14px;

        @media screen and (max-width: 1024px) {
          font-size: 12px;
          line-height: 130%;
          margin-bottom: 10px;
        }
      }

      .btn {
        display: block;
        padding: 23px 58px;
        border-radius: 100px;
        background: #e6f6ec;
        color: var(--Primary, #00a03b);

        /* Subheadline */
        font-family: $B;
        font-size: 20px;
        font-style: normal;
        line-height: 25px;
        margin: 52px auto 0 auto;

        position: relative;
        cursor: pointer;
        overflow: hidden;

        @media screen and (max-width: 1024px) {
          padding: 18px 58px;
          margin-top: 32px;
          font-size: 18px;
        }

        &:after {
          content: 'Cпробувати ще раз';
          opacity: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #00A03B;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          color: var(--Primary, #00A03B);
          font-family: $B;
          font-size: 20px;
          font-style: normal;

          line-height: 44px;

          @media screen and (max-width: 1024px) {
            font-size: 18px;
            line-height: 25px;
          }
        }

        &:not(.next_disabled):not(.next_disabled) {
          cursor: pointer;
          @media screen and (min-width: 1024px) {
            &:hover {
              &:after {
                opacity: 1;
                color: white;
              }

              color: white;
            }
          }

          &:active {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #00A03B;
            color: white;
          }
        }

        &_active {
          color: var(--Primary, #00a03b);
          background: rgba(0, 160, 59, 0.2);
        }
      }
    }
  }

  .packPopup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;

    display: flex;
    justify-content: center;
    align-items: center;

    background: rgba(0, 0, 0, 0.5);
    padding: 20px;


    &__add-scroll {
      overflow-y: auto;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__wrapper {
      //position: absolute;
      //left: 142px;
      //top: 50px;

      display: flex;
      max-width: 718px;
      width: 100%;
      max-height: 100%;
      //width: 1764px;
      //height: 1180px;
      padding: 64px;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      position: relative;

      border-radius: 29px;

      background: var(--white, #FFF);
      box-shadow: 0 20px 60px 0 rgba(0, 0, 0, 0.25);

      @media screen and (max-width: 1024px) {
        padding: 77px 27px;
      }

      @media screen and (max-height: 640px) and (max-width: 1024px) {
        padding: 20px;
      }
      @media screen and (max-height: 800px) and (min-width: 1025px) {
        padding: 20px;
      }


    }

    &__close {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;

      @media screen and (max-width: 1024px) {
        top: 20px;
        right: 20px;
        svg {
          width: 32px;
          height: 32px;
        }
      }
      @media screen and (max-height: 640px) and (max-width: 1024px) {
        top: 10px;
        right: 10px;
        svg {
          width: 24px;
          height: 24px;
        }
      }
      @media screen and (max-height: 800px) and (min-width: 1025px) {
        top: 10px;
        right: 10px;
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    &__pack {
      max-width: 320px;
      width: 100%;
      height: auto;
      margin-bottom: 20px;
      display: block;
    }

    &__title {
      color: var(--teva-grey, #424242);
      text-align: center;
      font-family: $B;
      font-size: 24px;
      font-style: normal;
      line-height: 24px; /* 101.896% */
      margin-bottom: 20px;

      @media screen and (max-width: 1024px) {
        font-size: 22px;
        line-height: normal;
        margin-bottom: 20px;
      }
    }

    &__price {
      color: var(--Primary, #00A03B);
      text-align: center;
      font-family: $EB;
      font-size: 48px;
      font-style: normal;
      line-height: 120%;
      margin-bottom: 20px;

      @media screen and (max-width: 1024px) {
        font-size: 32px;
      }
    }

    &__footer {
      color: var(--Text-Secondary, #8E8E8E);
      text-align: center;
      font-family: $R;
      font-size: 18px;
      font-style: normal;
      line-height: 34px;

      @media screen and (max-width: 1024px) {
        font-size: 13px;
        line-height: 34px;
      }
    }
  }

  .links {
    max-width: 820px;
    width: 100%;
    padding-top: 60px;

    margin: auto auto 0 auto;

    p {
      font-family: $R;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
</style>
